import React, { useEffect, useContext } from 'react';
import AppContext from './contexts/AppContext';
import Menu from './components/Menu';
import Login from './components/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tablero from './components/Tablero';
import Pedido from './components/Pedido';
import VentasTabla from './components/VentasTabla';
import Reportes from './components/Reportes';
import Direcciones from './components/DireccionesList';
import CierreCaja from './components/CierreCaja';
import Actividad from './components/Actividad';
import Cuenta from './components/Cuenta';
import Cupon from './components/Cupon';
import PrivateRoute from './components/PrivateRoute';
import ReporteDemoras from "./components/ReporteDemoras";
function App() {
    const {
        isLoggedIn, setIsLoggedIn,
        username, darkMode, setDarkMode,
        handleLogin
    } = useContext(AppContext);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token);

        if (token) {
            // const storedFullname = localStorage.getItem('fullname');
            // const storedUserId = localStorage.getItem('userId');
            // const storedUserRole = localStorage.getItem('userRole');
            // const storedUserManager = localStorage.getItem('userManager');
            //
            // console.log("Fullname desde localStorage:", storedFullname);
            // console.log("UserId desde localStorage:", storedUserId);
            // console.log("UserRole desde localStorage:", storedUserRole);
            // console.log("UserManager desde localStorage:", storedUserManager);
        }
    }, [setIsLoggedIn]);  // <-- Aquí agregamos setIsLoggedIn.


    useEffect(() => {
        if (isLoggedIn) {
            //console.log("Usuario logueado:", username);
        }
        // console.log('darkMode de App.js:', darkMode);
    }, [isLoggedIn, username, darkMode]);


    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            background: {
                default: darkMode ? '#464c50' : '#f5f5f5',
            },
        },
    });

    const toggleDarkMode = () => {
        setDarkMode(prev => !prev);
    };


    if (isLoggedIn === null) {
        return <div>Cargando...</div>;
    }

    return (

        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>
                {isLoggedIn ? (
                    <>
                        <Router>
                            <Menu onLogout={handleLogin} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
                            <Routes>

                                <Route
                                    path="/pedido"
                                    element={
                                        <PrivateRoute allowedRoles={['Pedido']}>
                                            <Pedido />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/tablero"
                                    element={
                                        <PrivateRoute allowedRoles={['Tablero']}>
                                            <Tablero />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/cierrecaja"
                                    element={
                                        <PrivateRoute allowedRoles={['Cierre Caja']}>
                                            <CierreCaja />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/actividad"
                                    element={
                                        <PrivateRoute allowedRoles={['Actividad']}>
                                            <Actividad />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/gestorventa"
                                    element={
                                        <PrivateRoute allowedRoles={['Gestor de Venta']}>
                                            <VentasTabla />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/direccion"
                                    element={
                                        <PrivateRoute allowedRoles={['Direcciones']}>
                                            <Direcciones />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/reportes"
                                    element={
                                        <PrivateRoute allowedRoles={['Reportes']}>
                                            <Reportes />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/reportes/demoras"
                                    element={
                                        <PrivateRoute allowedRoles={['Reporte demoras']}>
                                            <ReporteDemoras />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/cuenta"
                                    element={
                                        <PrivateRoute allowedRoles={['Cuenta']}>
                                            <Cuenta />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/cupon"
                                    element={
                                        <PrivateRoute allowedRoles={['Cupón']}>
                                            <Cupon />
                                        </PrivateRoute>
                                    }
                                />

                                {/* Otras rutas con el mismo patrón */}
                            </Routes>
                        </Router>
                    </>
                ) : (
                    <Login handleLogin={handleLogin} />
                )}
            </div>
        </ThemeProvider>
        /*
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>
                {isLoggedIn ? (
                    <>
                        <Router>
                            <Menu onLogout={handleLogin} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
                            <Routes>
                                <PrivateRoute path="/tablero" element={<Tablero />} allowedRoles={['Tablero']} />
                                <PrivateRoute path="/pedido" element={<Pedido />} allowedRoles={['Pedido']} />
                                <PrivateRoute path="/gestorventa" element={<VentasTabla />} allowedRoles={['Gestor de Venta']} />
                                <PrivateRoute path="/cierrecaja" element={<CierreCaja />} allowedRoles={['Cierre Caja']} />
                                <PrivateRoute path="/reportes" element={<Reportes/>} allowedRoles={['Reportes']} />
                                <PrivateRoute path="/direccion" element={<Direccion/>} allowedRoles={['Direcciones']} />
                                {/!* Define otras rutas según sea necesario *!/}
                            </Routes>
                        </Router>
                    </>
                ) : (
                    <Login handleLogin={handleLogin} />
                )}
            </div>
        </ThemeProvider>*/
    );
}

export default App;
