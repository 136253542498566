// config.js

const config = {
    baseURL: "https://lawisllaperu.com/api/",
    timezonewislla:"America/Lima",
    stockCheckInterval: 6000,
    initialDelay: 10000,
    rolesAccess: {
        0: ['Pedido', 'Tablero', 'Cierre Caja', 'Actividad', 'Gestor de Venta', 'Direcciones', 'Reportes','Reporte demoras', 'Cuenta', 'Cupón'],
        1: ['Pedido', 'Tablero', 'Cierre Caja', 'Actividad', 'Gestor de Venta', 'Direcciones', 'Reportes','Reporte demoras', 'Cuenta', 'Cupón'],
        2: ['Pedido', 'Tablero', 'Actividad', 'Direcciones', 'Cuenta'],
        3: ['Tablero','Actividad','Cuenta'],
        // Añade más roles según sea necesario
    },
    managerAccess: {
        1: ['Cupón', 'Cierre Caja','Reporte demoras' ], // acceso adicional para managers
        0: [] // usuarios normales, sin acceso adicional
    }
};

export default config;
