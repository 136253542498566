import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import config from '../system/config';
import ReporteDemorasConFechas from './ReporteDemorasConFechas';

const ReporteDemoras = () => {
    const [demoras, setDemoras] = useState([]);
    const [chartData, setChartData] = useState({ xAxis: [], series: [] });
    const [fechaMax, setFechaMax] = useState('');

    useEffect(() => {
        const fetchDemoras = async () => {
            try {
                const response = await axios.get(`${config.baseURL}/getDemoraDia`);
                if (response.data.status && response.data.data.length > 0) {
                    setDemoras(response.data.data);
                } else {
                    console.warn('La API no devolvió datos válidos para getDemoraDia.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDemoras();
    }, []);

    useEffect(() => {
        const processChartData = () => {
            if (demoras.length > 0) {
                const fechas = demoras.map((d) => new Date(d.creado));
                const maxFecha = new Date(Math.max(...fechas));
                setFechaMax(maxFecha.toLocaleDateString());

                const productos = [...new Set(demoras.map((d) => d.producto))];
                let data = productos.map((producto) => {
                    const productoDemoras = demoras.filter((d) => d.producto === producto);

                    // Validar y calcular promedios
                    const demorasNumericas = productoDemoras
                        .map((item) => parseFloat(item.demora))
                        .filter((value) => !isNaN(value) && value >= 0);

                    const demoraAvg =
                        demorasNumericas.length > 0
                            ? demorasNumericas.reduce((sum, value) => sum + value, 0) /
                            demorasNumericas.length
                            : 0;

                    return { producto, demoraAvg: demoraAvg.toFixed(2) };
                });

                // Ordenar de mayor a menor
                data.sort((a, b) => parseFloat(b.demoraAvg) - parseFloat(a.demoraAvg));

                setChartData({
                    xAxis: data.map((item) => item.producto),
                    series: data.map((item) => item.demoraAvg),
                });
            } else {
                console.warn('No hay datos de demoras para procesar el gráfico.');
            }
        };

        processChartData();
    }, [demoras]);

    const options = {
        title: {
            text: `Reporte de Demoras por Producto (Fecha: ${fechaMax})`,
            left: 'center',
        },
        grid: {
            left: '10%',
            right: '10%',
            bottom: '25%',
            top: '10%',
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                rotate: 45,
                interval: 0,
                fontSize: 9,
                align: 'center',
                margin: 40,
            },
        },
        yAxis: {
            type: 'value',
            name: 'Demora Promedio (min)',
        },
        series: [
            {
                data: chartData.series,
                type: 'bar',
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c} min',
                },
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: '#5470C6' },  // color en la parte superior
                            { offset: 1, color: '#91ccf2' },  // color en la parte inferior
                        ],
                        global: false,
                    },
                },
            },
        ],

        tooltip: {
            trigger: 'axis',
            formatter: '{b}: {c} min',
        },
    };

    return (
        <div>
            {chartData.xAxis.length > 0 ? (
                <ReactECharts option={options} style={{ height: '500px' }} />
            ) : (
                <p>No hay datos disponibles para generar el reporte.</p>
            )}
            <ReporteDemorasConFechas />
        </div>
    );
};

export default ReporteDemoras;
