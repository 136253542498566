import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Grid,
    Paper
} from '@mui/material';
import config from "../system/config";

const PronosticoCards = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const cardColors = ['#e53935', '#e53935', '#1e88e5', '#1e88e5', '#1e88e5',
        '#43a047', '#43a047', '#43a047', '#e88869'
        , '#8798af',
        '#33b7c3', '#d3bb85', '#71d6be', '#d677f1', '#d677f1',
        '#d677f1', '#7cb342', '#f6d63f', '#ff7043'];

    const fetchData = () => {
        axios.get(`${config.baseURL}/getPronostico`)
            .then(response => {
                if (response.data.status) {
                    setData(response.data.data[0]);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData(); // carga inicial

        const interval = setInterval(() => {
            fetchData(); // cada X segundos
        }, 20000); // 30 segundos

        return () => clearInterval(interval); // limpiar al desmontar
    }, []);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Paper elevation={3} sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                {Object.keys(data).map((key, index) => (
                    <Grid item xs={12} sm={6} md={3} key={key}>
                        <Card sx={{ backgroundColor: cardColors[index % cardColors.length] }}>
                            <CardContent>
                                <Typography variant="h6" fontWeight="bold">
                                    {key}
                                </Typography>
                                <Typography variant="h5" align="right" fontWeight="bold">
                                    {data[key]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default PronosticoCards;
