import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/icons/logowislla.png';
import axios from 'axios';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GridViewIcon from '@mui/icons-material/GridView';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BarChartIcon from '@mui/icons-material/BarChart';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Link } from 'react-router-dom';
import config from "../system/config";

function MenuComponent({ toggleDarkMode, darkMode, onLogout }) {

    const storedFullname = localStorage.getItem('fullname');

    const iconMap = {
        'Pedido': <EditNoteIcon />,
        'Tablero': <GridViewIcon />,
        'Cierre Caja': <PointOfSaleIcon />,
        'Actividad': <TransferWithinAStationIcon />,
        'Gestor de Venta': <MenuBookIcon />,
        'Direcciones': <ContactMailIcon />,
        'Reportes': <BarChartIcon />,
        'Reporte demoras': <AccessTimeIcon />,
        'Cuenta': <ManageAccountsIcon />,
        'Cupón': <CardGiftcardIcon />,
    };
    //'Pedido', 'Gestor de Venta', 'Tablero', 'Direcciones', 'Reportes', 'Cierre Caja', 'Actividad', 'Cuenta', 'Cupón'
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const userRole = localStorage.getItem('userRole'); // 'admin', 'manager', 'staff', etc.
    const userManager = localStorage.getItem('userManager'); // 'true', 'false', etc.

    //console.log('userRole, userManager', userRole, userManager);



    // Convierte el valor de userManager a un booleano, asumiendo que "1" significa verdadero (es manager).
    const isManager = parseInt(userManager) === 1; // Parsea a entero y compara.

    // Define los módulos iniciales; si el rol no existe en la configuración, no se permiten módulos.
    let modulesAllowed = userRole in config.rolesAccess ? config.rolesAccess[userRole] : [];

    // Si isManager es true, agrega los permisos de manager.
    if (isManager) {
        const managerPermissions = config.managerAccess[1]; // permisos adicionales para managers
        modulesAllowed = Array.from(new Set([...modulesAllowed, ...managerPermissions])); // Fusiona y elimina duplicados.
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = async () => {
        try {
            const token = localStorage.getItem('token'); // Obtener el token de la sesión del localStorage
            const response = await axios.post(`${config.baseURL}/logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}` // Agregar el token a los encabezados de la solicitud
                }
            });

            if (response.data) {
                localStorage.removeItem('token'); // Remover el token de la sesión del localStorage
                localStorage.removeItem('username');
                localStorage.removeItem('fullname');
                localStorage.removeItem('userId');
                localStorage.removeItem('userRole');
                localStorage.removeItem('userManager');
                onLogout(false);
                navigate("/pedido", { replace: true });
            }

        } catch (error) {
            console.error('Error al cerrar la sesión', error);
        }
    };


    return (
        <div>
            <AppBar position="static" sx={{backgroundColor: darkMode ? '#343a40' : '#f39a34'}}>
                <Toolbar style={{ minHeight: '35px' }}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                        <img src={logo} alt="Logo" style={{ width: '30px' }} />
                    </IconButton>
                    <Typography style={{flexGrow: 1, color: darkMode ? '#f1b979' : '#000000'}}>
                        La Wislla - Cajamarca
                    </Typography>
                    <IconButton color="inherit" onClick={toggleDarkMode} style={{color: darkMode ? '#f1b979' : '#000000'}}>
                        {darkMode ? <Brightness5Icon fontSize="small" /> : <Brightness2Icon fontSize="small" />}
                    </IconButton>
                    <IconButton color="inherit" style={{color: darkMode ? '#f1b979' : '#000000'}}>
                        <NotificationsIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={handleMenuOpen} style={{color: darkMode ? '#f1b979' : '#000000'}}>
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        id="logout-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleLogoutClick} style={{color: darkMode ? '#f1b979' : '#000000'}}>
                            <LogoutIcon fontSize="x-small" style={{color: darkMode ? '#f1b979' : '#000000'}} />
                            Salir   {storedFullname}  {/* Mostrar el username aquí */}
                        </MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={handleDrawerClose}>
                <List>
                    {[
                        { name: 'Pedido', path: '/pedido' },
                        { name: 'Tablero', path: '/tablero' },
                        { name: 'Cierre Caja', path: '/cierrecaja' },
                        { name: 'Actividad', path: '/actividad' },
                        { name: 'Gestor de Venta', path: '/gestorventa' },
                        { name: 'Direcciones', path: '/direccion' },
                        { name: 'Reportes', path: '/reportes' },
                        { name: 'Reporte demoras', path: '/reportes/demoras' },
                        { name: 'Cuenta', path: '/cuenta' },
                        { name: 'Cupón', path: '/cupon' },
                    ].filter(module => modulesAllowed.includes(module.name)).map(({ name, path }) => (
                        <Link to={path} style={{ textDecoration: 'none', color: darkMode ? '#f1b979' : '#000000', width: '100%' }} key={name}>
                            <ListItem button>
                                <ListItemIcon style={{ textDecoration: 'none', color: darkMode ? '#f1b979' : '#000000'}}>{iconMap[name]}</ListItemIcon>
                                <ListItemText primary={name} />
                            </ListItem>
                        </Link>
                    ))}
                </List>


            </Drawer>
        </div>
    );
}

export default MenuComponent;
