import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import config from '../system/config';
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    CircularProgress,
} from '@mui/material';

const ReporteDemorasConFechas = () => {
    const [demoras, setDemoras] = useState([]);
    const [chartData, setChartData] = useState({ xAxis: [], series: [] });
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchDemoras = async () => {
        if (!fechaInicio || !fechaFin) {
            alert('Por favor selecciona un rango de fechas válido.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(`${config.baseURL}/getDemoraTotal`);
            if (response.data.status) {
                const filteredData = response.data.data.filter((d) => {
                    const fechaCreado = new Date(d.creado);
                    const fechaInicioDate = new Date(fechaInicio);
                    const fechaFinDate = new Date(fechaFin);
                    return fechaCreado >= fechaInicioDate && fechaCreado <= fechaFinDate;
                });
                setDemoras(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const processChartData = () => {
            if (demoras.length > 0) {
                const productos = [...new Set(demoras.map((d) => d.producto))];
                const data = productos.map((producto) => {
                    const productoDemoras = demoras.filter((d) => d.producto === producto);
                    const demorasNumericas = productoDemoras
                        .map((item) => parseFloat(item.demora))
                        .filter((value) => !isNaN(value) && value >= 0);
                    const demoraAvg = demorasNumericas.length > 0
                        ? demorasNumericas.reduce((sum, value) => sum + value, 0) / demorasNumericas.length
                        : 0;
                    return { producto, demoraAvg: demoraAvg.toFixed(2) };
                });
                data.sort((a, b) => parseFloat(b.demoraAvg) - parseFloat(a.demoraAvg));
                setChartData({
                    xAxis: data.map((item) => item.producto),
                    series: data.map((item) => item.demoraAvg),
                });
            } else {
                setChartData({ xAxis: [], series: [] });
            }
        };
        processChartData();
    }, [demoras]);

    const options = {
        title: {
            text: `Reporte de Demoras (${fechaInicio} - ${fechaFin})`,
            left: 'center',
        },
        grid: {
            left: '10%',
            right: '10%',
            bottom: '25%',
            top: '15%',
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                rotate: 45,
                fontSize: 10,
                margin: 15,
            },
        },
        yAxis: {
            type: 'value',
            name: 'Demora Promedio (min)',
        },
        series: [
            {
                data: chartData.series,
                type: 'bar',
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c} min',
                },
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: '#7454c6' },  // color en la parte superior
                            { offset: 1, color: '#cbadf3' },  // color en la parte inferior
                        ],
                        global: false,
                    },
                },
            },
        ],

        tooltip: {
            trigger: 'axis',
            formatter: '{b}: {c} min',
        },
    };

    return (
        <Box sx={{ mt: 5, px: 3 }}>
            <Paper sx={{ p: 3, mb: 4 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Reporte de Demoras Acumuladas
                </Typography>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <TextField
                            label="Fecha Inicio"
                            type="date"
                            size="small"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Fecha Fin"
                            type="date"
                            size="small"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="success"
                            onClick={fetchDemoras}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Generar Reporte'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {chartData.xAxis.length > 0 ? (
                <ReactECharts option={options} style={{ height: '500px' }} />
            ) : (
                <Typography variant="body2" align="center" color="text.secondary">
                    No hay datos disponibles para el rango seleccionado.
                </Typography>
            )}
        </Box>
    );
};

export default ReporteDemorasConFechas;
