// Tablero.js
import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {useMediaQuery, Grid, Switch, FormControlLabel, Button, Dialog, DialogTitle, DialogContent} from '@mui/material';
import Mesa from './Mesa';
import axios from 'axios';
import AppContext from '../contexts/AppContext';
import audios from './audioMesas';
import ChipSet from './ChipSet';
import StockForm from './Stock';
import ConsumoDiaComponent from './ConsumoDia';
import 'react-toastify/dist/ReactToastify.css';
import ConsumoIcon from '@mui/icons-material/ShoppingCartCheckout';
import StockIcon from '@mui/icons-material/Inventory';
import PronosticoTable from './Pronostico';
import PreviewIcon from '@mui/icons-material/Preview';
import ProgressBarStock from './StockProgressBar';
import ProgressBarStockVertical from './StockProgressBarVertical';
import PageviewIcon from '@mui/icons-material/Pageview';
import config from "../system/config";
import StockAlert from './StockAlert';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import TimelineMesa from './TimelineMesa'; // Importa el nuevo componente

dayjs.extend(utc);
dayjs.extend(timezone);

function Tablero() {
    const roltablero = localStorage.getItem('userRole');
    // const managertablero = localStorage.getItem('userManager');
    //   console.log("Roltablero desde localStorage:", roltablero);
    //   console.log("Managertablero desde localStorage:", managertablero);
    const [data, setData] = useState([]);
    const [dataOrden, setDataOrden] = useState([]);
    const [datadetail, setDataDetail] = useState([]);
    const {darkMode} = useContext(AppContext);
    const mesas = new Array(48).fill(0); // Crea un array de 48 elementos
    const [audioEnabled, setAudioEnabled] = useState(false);  // Por defecto deshabilitado Audio
    const alertQueueRef = useRef([]);  // Cola de alertas
    const [alertTimeout, setAlertTimeout] = useState(null); // Controlador de tiempo para las alertas
    const [isConsumoDiaDialogOpen, setConsumoDiaDialogOpen] = useState(false);
    const [openPronosticoDialog, setOpenPronosticoDialog] = useState(false);
    const [newOrderAlerted, setNewOrderAlerted] = useState([]);
    const filtrarPorEstadoValidado = (data, estado) => {
        return data.filter(item => item.estadovalidado === estado);
    };

    const handleOpenPronosticoDialog = () => {
        setOpenPronosticoDialog(true);
    };

    const handleClosePronosticoDialog = () => {
        setOpenPronosticoDialog(false);
    };

    useEffect(() => {
        const validateAlerts = () => {
            if (!audioEnabled) return;

            const now = dayjs();
            const mesasToAlert = data
                .filter(item => {
                    const createdDate = dayjs(item.creado.replace(' ', 'T')).tz('America/Lima');
                    const minutesDifference = getMinutesDifference(now, createdDate);
                    return (
                        (item.estadovalidado === "4" && roltablero === '2' && item.reserva === "MESA") ||
                        (item.estadovalidado === "1" && item.reserva === "MESA" && minutesDifference > 10 && roltablero === '3')
                    );
                })
                .map(item => item.mesa);

            alertQueueRef.current = [...new Set([...alertQueueRef.current, ...mesasToAlert])];

            if (alertQueueRef.current.length > 0 && !alertTimeout) {
                playNextAlert();
            }
        };

        const intervalId = setInterval(validateAlerts, 20000);

        return () => clearInterval(intervalId);
    }, [audioEnabled, data, roltablero]);

    useEffect(() => {
        const checkNewOrders = () => {
            if (!audioEnabled) return;

            data.forEach(item => {
                if (item.estadovalidado === "1" && roltablero === '3' && !newOrderAlerted.includes(item.mesa)) {
                    const newOrderSound = new Audio(require('../assets/audio/mp3/ingresopedido.mp3'));
                    newOrderSound.play();
                    setNewOrderAlerted(prev => [...prev, item.mesa]);
                }
            });
        };

        const intervalId = setInterval(checkNewOrders, 5000);

        return () => clearInterval(intervalId);
    }, [audioEnabled, data, roltablero, newOrderAlerted]);

    const playNextAlert = useCallback(() => {
        if (alertQueueRef.current.length > 0) {
            const nextMesa = alertQueueRef.current[0];
            let sonidoSrc;
            if (roltablero === '3' && nextMesa <= 48) {
                sonidoSrc = require(`../assets/audio/mp3/excesivo${nextMesa}.mp3`);
            } else {
                sonidoSrc = getAudioSrcByMesa(nextMesa);
            }

            if (sonidoSrc) {
                const sonido = new Audio(sonidoSrc);
                sonido.play();

                sonido.onended = () => {
                    alertQueueRef.current = alertQueueRef.current.slice(1);
                    if (alertQueueRef.current.length > 0) {
                        setAlertTimeout(setTimeout(playNextAlert, 2000));
                    } else {
                        setAlertTimeout(null);
                    }
                };
            } else {
                alertQueueRef.current = alertQueueRef.current.slice(1);
                if (alertQueueRef.current.length > 0) {
                    playNextAlert();
                } else {
                    setAlertTimeout(null);
                }
            }
        }
    }, [data, roltablero]);

    const repopulateAlertQueue = () => {
        if (data) {
            const mesasToAlert = data
                .filter(item => item.estadovalidado === "4" && roltablero === '2')
                .map(item => item.mesa);
            alertQueueRef.current = [...new Set(mesasToAlert)];
        }
    };

    const getMinutesDifference = (date1, date2) => {
        // console.log('date 1  y date 2', date1, date2)
        const diffInMilliseconds = dayjs(date1).diff(dayjs(date2));
        // console.log('diffInMilliseconds', diffInMilliseconds / (1000 * 60));
        return diffInMilliseconds / (1000 * 60);
    };

    useEffect(() => {
        if (audioEnabled) {
            repopulateAlertQueue();
        }
    }, [audioEnabled, data, roltablero]);

    const getAudioSrcByMesa = (mesa) => audios[mesa - 1];

    useEffect(() => {
        if (!audioEnabled && alertTimeout) {
            clearTimeout(alertTimeout);
            setAlertTimeout(null); // Esto es importante para asegurarse de que no haya timeout activo
        }
    }, [audioEnabled, alertTimeout]);

    useEffect(() => {
        const validateAlerts = () => {
            if (!audioEnabled) return;

            const now = dayjs();
            const mesasToAlert = data
                .filter(item => {
                    const createdDate = dayjs(item.creado.replace(' ', 'T')).tz('America/Lima');
                    const minutesDifference = getMinutesDifference(now, createdDate);

                    return (
                        (item.estadovalidado === "4" && roltablero === '2' && item.reserva === "MESA") ||
                        (item.estadovalidado === "1" && item.reserva === "MESA" && minutesDifference > 10 && roltablero === '3')
                    );
                })
                .map(item => item.mesa);

            const uniqueQueue = [...new Set([...alertQueueRef.current, ...mesasToAlert])];
            alertQueueRef.current = uniqueQueue;

            if (alertQueueRef.current.length > 0 && !alertTimeout) {
                playNextAlert();
            }
        };

        const intervalId = setInterval(validateAlerts, 10000); // Verificación cada 10 segundos

        return () => clearInterval(intervalId);
    }, [audioEnabled, data, roltablero]);

    useEffect(() => {
        if (audioEnabled) {
            playNextAlert();
        }

        return () => {
            if (alertTimeout) {
                clearTimeout(alertTimeout);
            }
        };
    }, [audioEnabled, playNextAlert]);

    useEffect(() => {
        const fetchVentas = () => {
            axios.get(`${config.baseURL}/obtenerVenta`)
                .then(response => {
                    if (JSON.stringify(data) !== JSON.stringify(response.data.data)) {
                        setData(response.data.data);
                        repopulateAlertQueue();
                    }
                })
                .catch(error => {
                    console.error('Hubo un problema con la operación axios:', error);
                });
        };

        const fetchVentasDetalle = () => {
            axios.get(`${config.baseURL}/obtenerVentaDetalle`)
                .then(response => {
                    if (JSON.stringify(datadetail) !== JSON.stringify(response.data.data)) {
                        setDataDetail(response.data.data);
                    }
                })
                .catch(error => {
                    console.error('Hubo un problema con la operación axios:', error);
                });
        };

        const fetchVentasOrden = () => {
            const datosFiltrados = filtrarPorEstadoValidado(data, "1");
            setDataOrden(datosFiltrados);
            //  console.log('datosFiltrados', datosFiltrados);
        };

        // Llama a las funciones inicialmente
        fetchVentas();
        fetchVentasDetalle();
        fetchVentasOrden();

        // Establece un intervalo para llamar a las funciones
        const intervalId = setInterval(() => {
            fetchVentas();
            fetchVentasDetalle();
            fetchVentasOrden();
        }, 1000); // Reducir la frecuencia a 1 segundo

        return () => clearInterval(intervalId);
    }, [data, datadetail]); // Solo se vuelve a ejecutar cuando data o datadetail cambian



    // Estado para manejar la apertura del modal
    const [openModalStock, setOpenModalStock] = useState(false);

    // Funciones para abrir y cerrar el modal
    const handleOpenModalStock = () => {
        setOpenModalStock(true);
    };
    const handleCloseModalStock = () => {
        setOpenModalStock(false);
    };

    const handleOpenConsumoDiaDialog = () => {
        setConsumoDiaDialogOpen(true);
    };

    const handleCloseConsumoDiaDialog = () => {
        setConsumoDiaDialogOpen(false);
    };


    const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <Grid container spacing={0.5} sx={{ marginTop: '5px' }}> {/* Ajusta el valor de spacing a tu gusto */}
            <Grid container item xs={12} justifyContent="space-between" sx={{ marginBottom: '-25px'}}>
                {/* Switch alineado a la izquierda */}
                <Grid item xs={1} container alignItems="center">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={audioEnabled}
                                onChange={() => setAudioEnabled(!audioEnabled)}
                                name="audioEnabled"
                                color="primary"
                            />
                        }
                        label="Alertas"
                    />
                </Grid>

                {isLargeScreen ? (
                    // Layout para pantalla grande
                    <>
                        {/* ProgressBarStock al centro */}
                        <Grid item xs={8} alignItems="center" justifyContent="center">
                            <ProgressBarStock />
                        </Grid>
                        {/* Botones alineados a la derecha */}
                        <Grid item xs={3} container alignItems="center" justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Button
                                    size="small"
                                    color="info"
                                    onClick={handleOpenPronosticoDialog}
                                    startIcon={<PreviewIcon />}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    size="small"
                                    color="success"
                                    onClick={handleOpenConsumoDiaDialog}
                                    startIcon={<ConsumoIcon />}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    size="small"
                                    color="error"
                                    onClick={handleOpenModalStock}
                                    disabled={!(roltablero === "0" || roltablero === "1" || roltablero === "3")}
                                    startIcon={<StockIcon />}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    // Layout para pantalla pequeña
                    <Grid item xs={11} container alignItems="center" justifyContent="flex-end">
                        {/* Botón con PageviewIcon */}
                        <Grid item>
                            <Button
                                size="small"
                                color="secondary"
                                onClick={handleOpen}
                                startIcon={<PageviewIcon />}
                            />
                            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                                <DialogTitle>Stock</DialogTitle>
                                <DialogContent>
                                    <ProgressBarStockVertical />
                                </DialogContent>
                            </Dialog>
                        </Grid>

                        {/* Otros botones alineados a la derecha */}
                        <Grid item container xs={'auto'} alignItems="center" justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Button
                                    size="small"
                                    color="info"
                                    onClick={handleOpenPronosticoDialog}
                                    startIcon={<PreviewIcon />}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    size="small"
                                    color="success"
                                    onClick={handleOpenConsumoDiaDialog}
                                    startIcon={<ConsumoIcon />}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    size="small"
                                    color="error"
                                    onClick={handleOpenModalStock}
                                    disabled={!(roltablero === "0" || roltablero === "1" || roltablero === "3")}
                                    startIcon={<StockIcon />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {/* Aquí se agrega el componente TimelineMesa */}
            <Grid item xs={12} sx={{ marginBottom: '0px', padding: '0px' }}>
                <TimelineMesa dataOrden={dataOrden} />
            </Grid>

            {mesas.map((_, index) => {
                const mesaData = data?.find(item => item.mesa === `${index + 1}`) || {};
                const mesaDataDetail = datadetail?.filter(item => item.mesa === `${index + 1}`) || [];

                return (
                    <Grid item xs={6} sm={4} md={2} lg={1} xl={1} key={index}>
                        <Mesa mesaNum={index + 1} datadetail={mesaDataDetail} data={mesaData} darkMode={darkMode}
                              userRole={roltablero} />
                    </Grid>
                );
            })}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
                <ChipSet />
            </Grid>

            <Dialog open={openModalStock} onClose={handleCloseModalStock} fullWidth maxWidth="lg">
                <DialogTitle>Gestionar Stock del Día</DialogTitle>
                <DialogContent>
                    <StockForm />
                </DialogContent>
            </Dialog>

            <Dialog open={isConsumoDiaDialogOpen} onClose={handleCloseConsumoDiaDialog} fullWidth maxWidth="lg">
                <DialogTitle>Consumo del Día</DialogTitle>
                <DialogContent>
                    <ConsumoDiaComponent />
                </DialogContent>
            </Dialog>

            <Dialog open={openPronosticoDialog} onClose={handleClosePronosticoDialog} fullWidth maxWidth="xl">
                <DialogTitle>Pronóstico del Día</DialogTitle>
                <DialogContent>
                    <PronosticoTable />
                </DialogContent>
            </Dialog>

            <StockAlert audioEnabled={audioEnabled} /> {/* Pasa audioEnabled como prop */}
        </Grid>
    );
}

export default Tablero;

